import React, { Component } from "react";
import { LegacyCard as Card } from "@shopify/polaris";

class DesignCard extends Component {
  render() {
    let {
      id,
      title,
      description,
      active,
      openWizard,
      openModal: edit,
      handleActivation,
      deletable,
      handleDelete,
      isAdmin,
    } = this.props;
    if (description && description.length > 120) {
      description = description.substring(0, 120) + "...";
    }
    const text = active ? "Active" : "Activate";
    let actions = isAdmin
      ? [
          { content: "Edit", onAction: () => edit("config", id) },
          {
            content: "Wizard",
            url: openWizard() + "&active=" + id,
            external: true,
          },
        ]
      : [{ content: "Edit", onAction: () => edit("config", id) }];

    return (
      <div>
        <Card
          className="designCard"
          sectioned
          title={title}
          actions={actions}
          primaryFooterAction={{
            primary: true,
            disabled: active,
            content: text,
            onAction: () => handleActivation(id),
          }}
          secondaryFooterActions={[
            {
              content: "Delete",
              disabled: !deletable,
              onAction: () => handleDelete(id),
            },
          ]}
        >
          <p>{description}</p>
        </Card>
      </div>
    );
  }
}

export default DesignCard;
