import React, { Component } from "react";
import {
  Layout,
  LegacyCard as Card,
  TextField,
  Form,
  FormLayout,
  Button,
} from "@shopify/polaris";
import "./LabelSettings.css";

class LabelSettings extends Component {
  state = {
    inputlabel: undefined,
    buttonlabel: undefined,
    subtotal: undefined,
    total: undefined,
    discount: undefined,
    errorMessage: undefined,
  };

  componentDidMount() {
    const { labels } = this.props;
    this.setState({
      ...labels,
    });
  }

  handleChange = (field) => {
    return (value) => {
      this.setState({ [field]: value });
    };
  };

  handleSubmit = (event) => {
    const { handleSubmit } = this.props;
    handleSubmit(this.state);
  };

  render() {
    const { inputlabel, buttonlabel, subtotal, discount, total, errorMessage } =
      this.state;
    return (
      <Layout.AnnotatedSection
        title="Labels"
        description="Customize the text of buttons and labels."
      >
        <Card sectioned>
          <Form onSubmit={this.handleSubmit}>
            <FormLayout className="dcLabelSettings">
              <TextField
                value={errorMessage}
                onChange={this.handleChange("errorMessage")}
                label="Error message"
                type="text"
                helpText={
                  <span>
                    Message displayed when discount code was invalid. Default:
                    'Enter a valid discount code'.
                  </span>
                }
              />
              <TextField
                value={inputlabel}
                onChange={this.handleChange("inputlabel")}
                label="Input field"
                type="text"
                helpText={
                  <span>
                    Placeholder text displayed in the discount input field.
                    Default: 'Discount'.
                  </span>
                }
              />
              <TextField
                value={buttonlabel}
                onChange={this.handleChange("buttonlabel")}
                label="Button"
                type="text"
                helpText={
                  <span>
                    Text displayed on the button next to the discount input
                    field. Default: 'Apply'.
                  </span>
                }
              />
              <TextField
                value={subtotal}
                onChange={this.handleChange("subtotal")}
                label="Subtotal"
                type="text"
                helpText={
                  <span>
                    Text displayed next to the subtotal value. Default:
                    'Subtotal'.
                  </span>
                }
              />
              <TextField
                value={discount}
                onChange={this.handleChange("discount")}
                label="Discount"
                type="text"
                helpText={
                  <span>
                    Text displayed next to the applied coupon. Default:
                    'Discount'.
                  </span>
                }
              />
              <TextField
                value={total}
                onChange={this.handleChange("total")}
                label="Total"
                type="text"
                helpText={
                  <span>
                    Text displayed next to the new total value. Default:
                    'Total'.
                  </span>
                }
              />
              <Button submit>Save</Button>
            </FormLayout>
          </Form>
        </Card>
      </Layout.AnnotatedSection>
    );
  }
}

export default LabelSettings;
