import React, { Component } from 'react';
import { Modal } from '@shopify/polaris';

class ConfirmModal extends Component {
	render() {
		const { active, handleClose, handleConfirm } = this.props;
		return (
			<Modal
				open={active}
				title={'Confirm deletion'}
				onClose={handleClose}
				primaryAction={{
					content: 'Delete',
					onAction: handleConfirm
				}}
				secondaryActions={[
					{
						content: 'Cancel',
						onAction: handleClose
					}
				]}
			>
				<Modal.Section>
					<p>Are you sure you want to delete this config?</p>
				</Modal.Section>
			</Modal>
		);
	}
}

export default ConfirmModal;
