import React from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container);

const app = <App />;
const appWithBoundary = (
  <ErrorBoundary
    fallback={app}
    onError={(error) => console.error("Caught error:", error)}
  >
    {app}
  </ErrorBoundary>
);

root.render(appWithBoundary);
