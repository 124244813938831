import React, { Component } from "react";
import { Layout, List, CalloutCard } from "@shopify/polaris";
import supportSVG from "../assets/img/support2.svg";
import upgradeSVG from "../assets/img/upgrade.svg";
import { safeSessionStorage } from "../storage";

class SellCard extends Component {
  state = {
    helpCardVisible: true,
    clearcallCard: true,
  };
  componentDidMount() {
    const helpCardVisible = !safeSessionStorage.getItem("dcodeHelpcard");
    this.setState({ helpCardVisible });
    const clearcallCard = !safeSessionStorage.getItem("clearcallCard");
    this.setState({ clearcallCard });
  }
  render() {
    const { plan, apiAddress, shop, openModal } = this.props;
    const { helpCardVisible } = this.state;
    return (
      <Layout.Section>
        <div className={"dcodeCallout"}>
          {plan === "BASIC" ? (
            <CalloutCard
              title="Unlock discount value valculation"
              illustration={upgradeSVG}
              primaryAction={{
                content: "Start trial",
                url: apiAddress + "/charge/create/" + shop,
                external: true,
              }}
            >
              <List>
                <List.Item>
                  Let your customers see the discount value already in the cart.
                </List.Item>
                <List.Item>
                  Request a custom integration into your theme to skip the
                  loading time.
                </List.Item>
              </List>
              <br />
              Start your free 14-day trial to full feature access now.
              {/*<List>
								<List.Item>
									Let your customers see the discount value already in the cart
								</List.Item>
								<List.Item>Support automatic discounts and 'Justuno'.</List.Item>
								<List.Item>Chose from over 100 existing designs</List.Item>
								<List.Item>Access analytics of the discounts applied in the cart.</List.Item>
							</List>*/}
            </CalloutCard>
          ) : (
            ""
          )}
          {helpCardVisible ? (
            <CalloutCard
              title="Need help with the setup?"
              illustration={supportSVG}
              primaryAction={{
                content: "Request help",
                onAction: () => openModal("configrequest"),
              }}
              onDismiss={() => {
                safeSessionStorage.setItem("dcodeHelpcard", "dismissed");
                this.setState({ helpCardVisible: false });
              }}
            >
              <p>
                Don't you worry. Our support team is there for you. Simply click
                on the button below and let us know how and where you want the
                discount box to appear. We will take care of the setup for you.
                Free of charge.
              </p>
            </CalloutCard>
          ) : (
            ""
          )}
        </div>
      </Layout.Section>
    );
  }
}

export default SellCard;
