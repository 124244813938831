import React, { Component } from "react";
import { Layout, Text } from "@shopify/polaris";
class SellScreen extends Component {
  render() {
    return (
      <Layout.Section>
        <Text variant="heading4xl" as="p">
          Unlock all features
        </Text>
      </Layout.Section>
    );
  }
}

export default SellScreen;
