import React from "react";
import * as PropTypes from "prop-types";
import {
  FooterHelp,
  Link,
  Toast,
  Frame,
  Page,
  AppProvider,
} from "@shopify/polaris";
import { Settings, Skeleton, Analytics } from "./pages";
import {
  DesignEditModal,
  SupportModal,
  ConfirmModal,
  SetupModal,
  InfoModal,
  UpgradeModal,
} from "./modals";
import * as uuid from "uuid/v4";
import SimpleCrypto from "simple-crypto-js";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import { Provider } from "@shopify/app-bridge-react";
import { safeSessionStorage } from "./storage";

const cryptoKey = "key" + Date.now();
const crypt = new SimpleCrypto(cryptoKey);
const urlParams = new URLSearchParams(window.location.search);
const shop = urlParams.get("shop");
const host =
  urlParams.get("host") ??
  btoa(`admin.shopify.com/store/${shop.split(".")[0]}`);
let token = urlParams.get("token");
const apiAddress = process.env.REACT_APP_SERVER_ADDRESS;
const flomllr_token = process.env.REACT_APP_TOKEN;
const va_token = process.env.REACT_APP_VA_TOKEN;
const chargeUrl = apiAddress + "/charge/create/" + shop;

let given_va_token;
try {
  given_va_token = localStorage.getItem("va_token");
} catch {}

const isAdmin = va_token === given_va_token;

class App extends React.Component {
  static contextTypes = {
    easdk: PropTypes.object,
  };

  state = {
    activeModalConfig: {
      classes: {},
      labels: {},
      style: {},
    },
    activeModal: false,
  };

  componentWillMount = () => {
    const setToken = urlParams.get("va_token");
    if (setToken) {
      localStorage.setItem("va_token", setToken);
      window.location.href = "https://discountcart-va.netlify.com/";
    }
  };

  componentDidMount = async () => {
    const interfaceSettings = await this.getInterfaceSettings(shop);
    this.setState(interfaceSettings);
    const { styles, plan } = interfaceSettings || {};

    // Require paid plan to keep using the app
    if (plan === "BASIC") {
      this.openModal("upgrade");
      return;
    }

    if (styles) {
      const encryptedStyles = crypt.encrypt(JSON.stringify(styles));
      console.log("Data", encryptedStyles);
      safeSessionStorage.setItem("dcodeWizardConfigs", encryptedStyles);
    }

    this.handleWizard();
    const setupInitiated = safeSessionStorage.getItem("dcSetupInitiated");
    if (!setupInitiated) {
      safeSessionStorage.setItem("dcSetupInitiated", Date.now());
      this.openModal("setup");
    }
    safeSessionStorage.setItem("_tempd_", token);
  };

  backend = async (endpoint, body) => {
    console.log("backend", endpoint, body, flomllr_token, token);
    token = token || given_va_token || safeSessionStorage.getItem("_tempd_");
    const response = await fetch(apiAddress + endpoint, {
      mode: "cors",
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ flomllr_token, token, ...body }),
    });
    console.log(response);
    return await response.json();
  };

  handleWizard = async () => {
    let wizardHelp = safeSessionStorage.getItem("dcodeSupportMessage");
    if (wizardHelp) {
      safeSessionStorage.removeItem("dcodeSupportMessage");
      this.handleSupportSend(
        {
          email: "support@flomllr.com",
          message: shop + " has a problem with the wizard",
        },
        true
      );
      this.handleWizardHelp();
      this.openModal(
        "support",
        undefined,
        `I have a problem with the setup wizard. (Please describe your problem in as much detail as possible)`
      );
      this.saveWizardSettings();
    } else {
      this.logToTelegram(shop + "successfully completed the wizard");
      this.saveWizardSettings(true);
    }
  };

  logToTelegram(message) {
    //this.backend('/telegram/log', { message });
  }

  saveWizardSettings = async (activate) => {
    console.log("Saving wizard settigns");
    let config = safeSessionStorage.getItem("finishedDcodeSetupSettings");
    console.log(config);
    if (config) {
      safeSessionStorage.removeItem("finishedDcodeSetupSettings");
      config = JSON.parse(config);
      const { id } = config || {};
      console.log(config, id);
      if (config && id) {
        // Check if config is edit of existing one
        const { styles } = this.state || { styles: [] };
        const existingConfig = styles && styles.find((e) => e.id === id);
        if (existingConfig) {
          console.log("EXISTING CONFIG");
          // Check if config was created by somebody else
          if (existingConfig.shop !== shop) {
            console.log("COPY OF IMMUTABLE");
            config.shop = shop;
            config.id = uuid();
            config.title = "Wizardedit of " + existingConfig.title;
            config.description =
              existingConfig.description +
              "(Modified: " +
              new Date().toLocaleString() +
              ")";
            styles.push(config);
          } else {
            console.log("UPDATE OWN CONFIG");
            // Config was existing but created by shopowner
            styles.forEach((e) => {
              if (e.id === id) {
                console.log("Updating existing config", e.title);
                e = config;
              }
            });
          }
        } else {
          console.log("NEW CONFIG");
          config.shop = shop;
          styles.push(config);
        }
        await this.saveConfigToDatabase(config);
        if (activate) await this.handleDesignActivation(config.id);
        this.setState({ styles });
      }
    }
  };

  getInterfaceSettings = async (shop) => {
    console.log("Getting settings from backend");
    const { settings } = (await this.backend("/fullsettings", { shop })) || {
      styles: [],
    };
    const { activeStyle } = settings || {};
    settings &&
      settings.styles.forEach((e) => {
        if (e.id === activeStyle) {
          e.active = true;
        }
      });
    console.log(settings);
    return settings;
  };

  saveConfigToDatabase = async (config) => {
    const { error, result } = await this.backend("/config/set", {
      shop,
      config,
    });
    if (error) this.toggleToast("Error saving design", true);
    else this.toggleToast("Design saved");
    console.log(result);
  };

  handleGeneralSettingsChange = async (setting) => {
    let state = this.state;
    state[setting] = !state[setting];
    this.setState(state);
    const body = { shop };
    body[setting] = state[setting];
    const { error } = await this.backend("/setting/set", body);
    if (error) this.toggleToast("Error updating settings", true);
    else this.toggleToast("Settings updated");
  };

  handleLabelSubmit = async (labels) => {
    this.setState(labels);
    const { error } = await this.backend("/labels/set", {
      shop,
      labels,
    });
    if (error) this.toggleToast("Error saving labels", true);
    else this.toggleToast("Labels saved");
  };

  handleDesignActivation = async (id) => {
    const { styles, activeStyle } = this.state;
    styles.forEach((e) => {
      if (e.id === id) {
        e.active = true;
      } else if (e.id === activeStyle) {
        e.active = false;
      }
    });
    this.setState({
      activeStyle: id,
      styles,
    });
    const { error } = await this.backend("/config/activate", { shop, id });
    if (error) this.toggleToast("Error activating design", true);
    else this.toggleToast("Design activated");
  };

  handleDesignDelete = async (id) => {
    let { styles, activeStyle } = this.state;
    if (id === activeStyle) {
      this.toggleToast("You can't delete the active config", true);
      return;
    }
    const style = styles.find((e) => e.id === id);
    if (style && style.shop !== shop) {
      this.toggleToast("You can't delete a premade config", true);
      return;
    }
    this.openModal("confirmDelete", id);
  };

  handleDesignDeleteConfirm = async () => {
    let { deleteId: id, styles } = this.state;
    styles = styles.filter((e) => e.id !== id);
    this.setState({
      styles,
      activeModal: false,
      deleteId: undefined,
    });
    const { error } = await this.backend("/config/delete", { shop, id });
    if (error)
      this.toggleToast("Error deleting design. Please reload the page.", true);
    else this.toggleToast("Design deleted");
  };

  handleDesignSave = () => {
    let { styles, activeModalConfig: a } = this.state;
    delete a.copy;
    this.saveConfigToDatabase(a);
    let styleUpdated = false;
    styles = styles.map((style) => {
      if (a.id === style.id) {
        const newStyle = Object.assign({}, a);
        styleUpdated = true;
        style = newStyle;
      }
      return style;
    });
    if (!styleUpdated) {
      styles.push(Object.assign({}, a));
    }
    this.setState({
      styles,
      activeModal: false,
    });
  };

  handleDesignChange = (key1, key2) => {
    const { activeModalConfig } = this.state;
    return (value) => {
      if (key2) {
        activeModalConfig[key1][key2] = value;
      } else {
        activeModalConfig[key1] = value;
      }
      this.setState({ activeModalConfig });
    };
  };

  closeModal = () => {
    this.setState({ activeModal: false });
  };

  openModal = (type, id, predefinedMessage) => {
    if (type === "support" || type === "configrequest") {
      const supportSubject =
        type === "configrequest" ? "Configuration request" : "Support request";
      this.setState({ supportSubject, activeModal: type, predefinedMessage });
    } else if (type === "confirmDelete") {
      this.setState({ activeModal: type, deleteId: id });
    } else if (type === "setup") {
      this.setState({ activeModal: type });
    } else if (type === "calculationMethodInfo") {
      this.setState({ activeModal: "calculationMethodInfo" });
    } else if (type === "upgrade") {
      this.setState({ activeModal: "upgrade" });
    } else {
      if (id) {
        const { styles } = this.state;
        styles.forEach((e) => {
          if (e.id === id) {
            const activeStyle = Object.assign({}, e);
            if (e.shop !== shop) {
              activeStyle.id = uuid();
              activeStyle.title = "Copy of " + e.title;
              activeStyle.copy = true;
              activeStyle.shop = shop;
              activeStyle.active = false;
            }
            this.setState({
              activeModalConfig: activeStyle,
              activeModal: "config",
            });
          }
        });
      } else {
        this.setState({
          activeModalConfig: {
            id: uuid(),
            title: "New Design",
            classes: {},
            labels: {},
            style: {},
            shop,
          },
          activeModal: "config",
        });
      }
    }
  };

  toggleToast = (message, error) => {
    console.log("Show toast");
    const { toast } = this.state;
    if (toast && toast.visible) {
      this.setState({ toast: { visible: false } });
    } else {
      this.setState({
        toast: {
          visible: true,
          message: message,
          error,
        },
      });
    }
  };

  handleSupportSend = async (support, hideToast) => {
    this.closeModal();
    const { error, result } = this.backend("/support", {
      shop,
      type: this.state.supportSubject,
      ...support,
    });
    console.log(error, result);
    if (!hideToast) this.toggleToast("Request sent");
  };

  handleWizardHelp = async () => {
    const { error, result } = this.backend("/wizardhelp", {
      shop,
    });
    console.log(error, result);
  };

  openWizard = () => {
    return (
      "https://" +
      shop +
      "/cart?dcodewizard=" +
      shop +
      "&key=" +
      cryptoKey +
      (apiAddress === "https://dev.flomllr.com/shopify" ? "&dev=true" : "") +
      (given_va_token && va_token === given_va_token ? "&va=true" : "")
    );
  };

  render() {
    const {
      labels,
      styles,
      plan,
      toast,
      appActive,
      calcActive,
      activeModal,
      advancedDiscountAdding,
    } = this.state;

    const {
      message: toastMessage,
      visible: toastVisible,
      error: toastError,
    } = toast || {};

    const { location, history } = this.props;
    const historyWithSearch = {
      replace: (path) =>
        history.replace({ pathname: path, search: location.search }),
    };

    const toastMarkup = toastVisible ? (
      <Frame>
        <Page>
          <Toast
            content={toastMessage}
            onDismiss={this.toggleToast}
            duration={2500}
            error={toastError}
          />
        </Page>
      </Frame>
    ) : undefined;

    const modal =
      activeModal === "config" ? (
        <DesignEditModal
          active={this.state.activeModal}
          handleSave={this.handleDesignSave}
          handleClose={this.closeModal}
          config={this.state.activeModalConfig}
          handleChange={this.handleDesignChange}
        />
      ) : activeModal === "support" || activeModal === "configrequest" ? (
        <SupportModal
          active={this.state.activeModal}
          handleClose={this.closeModal}
          handleSend={this.handleSupportSend}
          requestDesign={activeModal === "configrequest"}
          predefinedMessage={this.state.predefinedMessage}
        />
      ) : activeModal === "confirmDelete" ? (
        <ConfirmModal
          active={this.state.activeModal}
          handleClose={this.closeModal}
          handleConfirm={this.handleDesignDeleteConfirm}
        />
      ) : activeModal === "setup" ? (
        <SetupModal
          active={this.state.activeModal}
          handleClose={this.closeModal}
          openWizard={this.openWizard}
        />
      ) : activeModal === "calculationMethodInfo" ? (
        <InfoModal
          active={this.state.activeModal}
          handleClose={this.closeModal}
        />
      ) : activeModal === "upgrade" ? (
        <UpgradeModal
          active={this.state.activeModal}
          handleClose={this.closeModal}
          chargeUrl={chargeUrl}
        />
      ) : undefined;

    const config = {
      apiKey: process.env.REACT_APP_SHOPIFY_KEY,
      host,
      forceRedirect: !given_va_token || given_va_token !== va_token,
    };

    console.log("config", { url: window.location.search, config });

    return (
      <AppProvider i18n={{}}>
        <Provider
          config={config}
          router={{ location, history: historyWithSearch }}
        >
          <React.Fragment>
            <React.Fragment>
              {this.state.plan ? (
                <Switch>
                  <Route path="/analytics">
                    <Analytics token={token} />
                  </Route>
                  <Route path="/">
                    <Settings
                      shop={shop}
                      token={token}
                      apiAddress={apiAddress}
                      labels={labels}
                      handleLabelSubmit={this.handleLabelSubmit}
                      handleDesignActivation={this.handleDesignActivation}
                      openModal={this.openModal}
                      styles={styles}
                      plan={plan}
                      handleGeneralSettingsChange={
                        this.handleGeneralSettingsChange
                      }
                      appActive={appActive}
                      calcActive={calcActive}
                      openWizard={this.openWizard}
                      handleDesignDelete={this.handleDesignDelete}
                      advancedDiscountAdding={advancedDiscountAdding}
                      isAdmin={isAdmin}
                    />
                  </Route>
                </Switch>
              ) : (
                <Skeleton />
              )}

              <FooterHelp>
                Need help? Message me at{" "}
                <Link url="mailto:support@flomllr.com">
                  support@flomllr.com
                </Link>
                .
              </FooterHelp>
              {modal}
            </React.Fragment>
            {toastMarkup}
          </React.Fragment>
        </Provider>
      </AppProvider>
    );
  }
}

const AppWithRouter = withRouter(App);

class AppWrapper extends React.Component {
  render() {
    return (
      <Router>
        <AppWithRouter />
      </Router>
    );
  }
}

export default AppWrapper;

/**
 *
 *
 * Styles in eigener Datebank. Im App state ist folgendes gespeichert:
 * - Labels
 * - Planname
 * - Liste von geaddeten styles, jedes style element hat
 * 		- ID
 * 		- Namen
 * 		- Beschreibung
 * 		- Active true / false
 * - ID des aktiven Styles
 * - die Daten aller Style elemente sind im Local storage unter der jeweiligen ID gespeichert
 *
 * Benötigte Backend funktionen;
 * - getInterfaceSettings -> soll Labels, Plannamen und Liste der Styles zurückgeben
 * - getStyles(List) -> soll Object mit allen geforderten Style objekten zurückgeben
 *
 * Edit Style:
 * - Sobald eine Änderung an einem fremden Style objekt vorgenommen wird, wird ein neues
 * Style objekt erstellt. Sobald die Änderung gespeichert wird, wird das Style objekt in der
 * Datenbank gespeichert und die Styleliste im Shopobjekt aktualisiert.
 *
 * Activate Style:
 * - In styleliste des Appstates momentan aktives Element auf inaktiv setzen
 * 		und neues Style Element auf aktiv
 * - Im state aktives Element auf neue ID setzen
 * - Aktiven style in die Datenbank im Shopobjekt speichertn
 * -
 *
 *
 * TODO:
 * - Nicht vollständige Settings zum Client übertragen, nur was gebraucht wird
 * - Implementiertung von check wie viele Codes shop hat, falls > 250 dann alternative check variante
 * - Feedbackmodal wenn neuer Style gespeichert wird
 * - Edit Change bug -> 'would modify _id'
 * - Edit labels
 * - Front end anpassen an neue struktur
 * 
 * - TODO NEXT:
 * Fix forced redirect problem
 *
 * <AppProvider
				apiKey={process.env.REACT_APP_SHOPIFY_KEY}
				shopOrigin={'https://' + shop}
				forceRedirect='false'
			></AppProvider>
			//apiKey={process.env.REACT_APP_SHOPIFY_KEY}
 */

/*
		return {
			labels: {
				inputlabel: 'Discount',
				buttonlabel: 'Apply'
			},
			plan: 'PAID',
			styles: [
				{
					title: 'Automatic',
					immutable: true,
					id: '1',
					theme: 'All themes',
					description: `This style with automatically adjust to your
					theme by adding existing class names to input field and button.`,
					active: true,
					author: 'codeincart2.myshopify.com',
					anchor: '.cart_bottom',
					location: 'afterbegin',
					infoboxAnchor: '',
					infoboxLocation: '',
					classes: {
						outerWrapper: 'outer-wrapper',
						innerWrapper: 'inner-wrapper',
						input: 'discount-input',
						button: 'discount-button'
					},
					labels: {
						input: 'Discount',
						button: 'Apply'
					},
					automaticStyle: true,
					style: {
						outerWrapper: 'background-color: green;',
						innerWrapper: 'backgrund-color: red;',
						input: 'background-color: blue;',
						button: 'background-color: yellow'
					}
				},
				{
					title: 'Minimal',
					id: '2',
					theme: 'All themes',
					description: `Basic styling, fitting to all kinds of shops.`,
					active: false,
					author: 'codeincart2.myshopify.com'
				},
				{
					title: 'Minimal',
					id: '3',
					theme: 'All themes',
					description: `Basic styling, fitting to all kinds of shops.`,
					active: false,
					author: 'codeincart2.myshopify.com'
				},
				{
					title: 'My first custom style',
					id: '4',
					theme: 'Minimal',
					description: `Minimal design with focus on functionality, simplicity and beauty.`,
					active: false,
					author: 'codeincart2.myshopify.com'
				}
			],
			activeStyle: '1'
			
		};*/
