import React, { Component } from 'react';
import { Modal, FormLayout, TextField } from '@shopify/polaris';
import './DesignEditModal.css';

class SupportModal extends Component {
	state = {
		email: undefined,
		message: undefined
	};

	componentDidMount = () => {
		const { predefinedMessage: message } = this.props;
		if (message) this.setState({ message });
	};

	handleChange = field => {
		return value => {
			let state = this.state;
			state[field] = value;
			this.setState(state);
		};
	};

	handleSend = () => {
		this.props.handleSend(this.state);
	};

	render() {
		const { active, handleClose, requestDesign } = this.props;
		const { email, message } = this.state;
		return (
			<Modal
				open={active}
				title={requestDesign ? 'Request handmade design' : 'Support'}
				onClose={handleClose}
				primaryAction={{
					content: 'Send',
					onAction: this.handleSend
				}}
				secondaryActions={[
					{
						content: 'Cancel',
						onAction: handleClose
					}
				]}
			>
				<Modal.Section>
					<FormLayout>
						<TextField
							type='email'
							label='Email'
							onChange={this.handleChange('email')}
							value={email}
						/>
						<TextField
							label={requestDesign ? 'Description of design' : 'Message'}
							onChange={this.handleChange('message')}
							value={message}
							helpText={
								requestDesign
									? `Please describe your desired design and the position of the discount box. We will get back to you within 24 hours.`
									: 'We will get back to you within 24 hours.'
							}
						/>
					</FormLayout>
				</Modal.Section>
			</Modal>
		);
	}
}

export default SupportModal;
