function isSessionStorageSupported() {
  try {
    var storage = window.sessionStorage;
    storage.setItem("test", "test");
    storage.removeItem("test");
    return true;
  } catch (e) {
    return false;
  }
}

function isLocalStorageSupported() {
  try {
    var storage = window.localStorage;
    storage.setItem("test", "test");
    storage.removeItem("test");
    return true;
  } catch (e) {
    return false;
  }
}

const storageMock = {
  getItem: () => {
    console.warn("storage not supported");
  },
  setItem: () => {
    console.warn("storage not supported");
  },
  removeItem: () => {
    console.warn("storage not supported");
  },
};

export const safeSessionStorage = isSessionStorageSupported()
  ? window.sessionStorage
  : storageMock;

export const safeLocalStorage = isLocalStorageSupported()
  ? window.localStorage
  : storageMock;
