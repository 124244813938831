import React, { Component } from "react";
import { Modal, Button, Text } from "@shopify/polaris";

class UpgradeModal extends Component {
  render() {
    const { active, handleClose, chargeUrl } = this.props;
    console.log("charge id", chargeUrl);
    return (
      <Modal open={active} onClose={handleClose}>
        <Modal.Section>
          <Text variant="heading2xl" as="p">
            <span role="img" aria-label="speaker icon">
              📣
            </span>{" "}
            Please upgrade your plan
          </Text>
          <p style={{ margin: "30px 0" }}>
            We're evolving to serve you better! As part of our ongoing
            improvements, the free plan for Dcode will be phased out. But don't
            worry! Experience all the enhanced features by upgrading to our pro
            plan. Enjoy a 14-day free trial to get started.
            <br />
          </p>
          <Button url={chargeUrl} external primary size="large" fullWidth>
            Upgrade
          </Button>
        </Modal.Section>
      </Modal>
    );
  }
}

export default UpgradeModal;
