import React, { Component } from "react";
import { Modal, Text } from "@shopify/polaris";
import "./DesignEditModal.css";

class InfoModal extends Component {
  render() {
    const { active, handleClose } = this.props;
    return (
      <Modal
        open={active}
        title={"Which discount validation method is better?"}
        onClose={handleClose}
        primaryAction={[
          {
            content: "Close",
            onAction: handleClose,
          },
        ]}
      >
        <Modal.Section>
          <Text variant="headingMd" as="h3">
            Default
          </Text>
          <p>
            The default validation method is the safe choice. It works with any
            shop and any type of discount. Compared to the advanced method, it
            is slightly slower.
          </p>
          <br />
          <Text variant="headingMd" as="h3" element="h3">
            Advanced (Beta)
          </Text>
          <p>
            If your store has less than 250 discount codes (and no bulk
            discounts), it can take advantage of the advanced validation method.
            The codes are encrypted and cached in the browser to allow lightning
            fast discount validation and calculation. Note that this method is
            currently in beta - please test if your discount codes are
            calculated correctly and give me feedback at{" "}
            <a href="mailto:support@flomllr.com">support@flomllr.com</a>
          </p>
        </Modal.Section>
      </Modal>
    );
  }
}

export default InfoModal;
