import React, { Component } from "react";
import { Modal, Button, Text } from "@shopify/polaris";

class SetupModal extends Component {
  render() {
    const { active, handleClose } = this.props;
    return (
      <Modal open={active} onClose={handleClose}>
        <Modal.Section>
          <Text variant="heading2xl" as="p">
            Let's set everything up!
          </Text>
          <p style={{ margin: "30px 0" }}>
            You can customise the appearance of the Discount Box by editing one
            of the existing of the existing configurations in the 'Design'
            section.
            <br />
            <br />
            <b>
              We'd be happy to do handle the setup for you. Just press the
              "Request handmade design" button, describe your desired design,
              and we'll set everything up for you in 24 hours.
            </b>
          </p>
          <Button onClick={handleClose} external primary size="large" fullWidth>
            Get started
          </Button>
        </Modal.Section>
      </Modal>
    );
  }
}

export default SetupModal;
