import React, { useEffect, useState, useCallback } from "react";
import {
  Page,
  Layout,
  Text,
  DataTable,
  LegacyCard as Card,
  Filters,
  Badge,
} from "@shopify/polaris";
import firebase from "firebase";
import { DateRangePicker } from "react-date-range";
import "firebase/firestore";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { AreaChart } from "react-chartkick";
import "chart.js";
import { safeSessionStorage } from "../storage";

let initialized = false;

const Analytics = ({ token }) => {
  const [docs, setDocs] = useState([]);
  const [queryValue, setQueryValue] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const defaultDateRange = {
    startDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30),
    endDate: new Date(Date.now() + 1000 * 60 * 60 * 24),
    key: "selection",
  };

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      // Initialize Cloud Firestore through Firebase
      const firebaseConfig = {
        apiKey: "AIzaSyD-VER0rVB57ryAW-5cmx4PAMnMlZ9UGig",
        authDomain: "dcode-b074f.firebaseapp.com",
        databaseURL: "https://dcode-b074f.firebaseio.com",
        projectId: "dcode-b074f",
        storageBucket: "dcode-b074f.appspot.com",
        messagingSenderId: "204588739096",
        appId: "1:204588739096:web:36eb0774d338254654316d",
      };
      firebase.initializeApp(firebaseConfig);
    }
    const db = firebase.firestore();
    db.collection("shops")
      .doc(token ?? safeSessionStorage.getItem("_tempd_"))
      .collection("discountapplications")
      .onSnapshot(({ docs }) => {
        if (docs) {
          const unpackedDocs = docs.map((doc) => doc.data());
          setDocs(unpackedDocs);
        }
      });
  }, [token]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Initialize discounts by date
  const { startDate, endDate } = dateRange || defaultDateRange;
  const validDiscountsByDate = {};
  const invalidDiscountsByDate = {};
  if (startDate.toDateString() === endDate.toDateString()) {
    // Hour View
    for (let t = 0; t < 24; t++) {
      validDiscountsByDate[t + ":00"] = 0;
      invalidDiscountsByDate[t + ":00"] = 0;
    }
  } else if (endDate - startDate <= 1000 * 60 * 60 * 24 * 32) {
    // Day view
    for (let d = new Date(startDate); d < endDate; d.setDate(d.getDate() + 1)) {
      validDiscountsByDate[d.toDateString()] = 0;
      invalidDiscountsByDate[d.toDateString()] = 0;
    }
  } else {
    // Month view
    for (
      let d = new Date(startDate);
      d < endDate;
      d.setMonth(d.getMonth() + 1)
    ) {
      const dateString = months[d.getMonth()] + " " + d.getFullYear();
      validDiscountsByDate[dateString] = 0;
      invalidDiscountsByDate[dateString] = 0;
    }
  }

  const discountOverview = {};
  // Count discounts
  let discountTotal = 0;
  docs.forEach(({ discount, date: { seconds }, valid }) => {
    if (
      seconds &&
      (!queryValue || discount.includes(queryValue.toUpperCase()))
    ) {
      const date = new Date(seconds * 1000);
      // Limit discounts applied after 9th jan
      if (date > 1578584315364 && date >= startDate && date <= endDate) {
        discountTotal++;
        if (discountOverview[discount + valid]) {
          discountOverview[discount + valid].amount++;
        } else {
          discountOverview[discount + valid] = { discount, amount: 1, valid };
        }

        let dateString;
        if (startDate.toDateString() === endDate.toDateString()) {
          dateString = date.getHours() + ":00";
        } else if (endDate - startDate <= 1000 * 60 * 60 * 24 * 32) {
          // Day view
          dateString = date.toDateString();
        } else {
          // Month view
          dateString = months[date.getMonth()] + " " + date.getFullYear();
        }

        // No need to check whether entry exists because it was initialized earlier
        valid
          ? validDiscountsByDate[dateString]++
          : invalidDiscountsByDate[dateString]++;
      }
    }
  });

  const filteredDiscounts = Object.values(discountOverview)
    .sort((a, b) => (a.amount < b.amount ? 1 : -1))
    .map((obj) => [
      <p>
        {obj.discount}{" "}
        <Badge status={obj.valid ? "success" : "attention"}>
          {obj.valid ? "Valid" : "Invalid"}
        </Badge>
      </p>,
      obj.amount,
    ]);

  // Define filters
  const filters = [];

  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  const handleDateRangeChange = useCallback(({ selection }) => {
    setDateRange(selection);
  }, []);

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Text variant="heading4xl" as="p">
            Analytics
          </Text>
        </Layout.Section>
        <Layout.Section>
          <Text variant="headingMd" as="h2">
            Discount code validations
          </Text>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <Card.Section>
              <AreaChart
                data={[
                  { name: "Valid", data: validDiscountsByDate },
                  { name: "Invalid", data: invalidDiscountsByDate },
                ]}
              />
            </Card.Section>
          </Card>
          <Card>
            <Card.Section>
              <Filters
                queryValue={queryValue}
                filters={filters}
                onQueryChange={handleFiltersQueryChange}
                onQueryClear={handleQueryValueRemove}
                onClearAll={handleFiltersClearAll}
              />
            </Card.Section>
            <Card.Section>
              <DateRangePicker
                ranges={[dateRange || defaultDateRange]}
                onChange={handleDateRangeChange}
                months={2}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                direction="horizontal"
              />
            </Card.Section>
          </Card>
          <Card>
            <div className="discountTable">
              <DataTable
                columnContentTypes={["text", "numeric"]}
                headings={["Discount", "Number of validations"]}
                totals={["", discountTotal]}
                rows={filteredDiscounts}
                initialSortColumnIndex={1}
                sortable={[false, false]}
              />
            </div>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};
export default Analytics;
