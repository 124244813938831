import React, { Component } from "react";
import {
  Layout,
  SettingToggle,
  Form,
  FormLayout,
  Select,
  LegacyCard as Card,
  Text,
} from "@shopify/polaris";
class GeneralSettings extends Component {
  render() {
    const {
      plan,
      appActive,
      calcActive,
      handleChange,
      advancedDiscountAdding,
      openModal,
      apiAddress,
      shop,
    } = this.props;
    return (
      <Layout.AnnotatedSection
        title="General"
        description="Enable or disable app features"
      >
        <Form onSubmit={this.handleSubmit}>
          <FormLayout>
            <SettingToggle
              action={{
                content: appActive ? "Disable" : "Enable",
                onAction: () => handleChange("appActive"),
              }}
            >
              The app is{" "}
              <Text as="span" fontWeight="semibold">
                {appActive ? "enabled" : "disabled"}
              </Text>
            </SettingToggle>
            <SettingToggle
              action={{
                content: calcActive ? "Disable" : "Enable",
                onAction: () => handleChange("calcActive"),
                disabled: !(plan === "PAID" || plan === "PREUPDATE"),
              }}
            >
              Discount value calculation{" "}
              <Text as="span" fontWeight="semibold">
                {calcActive ? "enabled" : "disabled"}
              </Text>
              <br />
              {!(plan === "PAID" || plan === "PREUPDATE") && (
                <a
                  href={apiAddress + "/charge/create/" + shop}
                  style={{
                    fontSize: "0.8em",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text as="span" color="subdued">
                    Start the Pro trial to use this option
                  </Text>
                </a>
              )}
            </SettingToggle>
            {!advancedDiscountAdding && (
              <Card sectioned>
                <p>
                  Discount validation method{" "}
                  <Text as="span" color="subdued">
                    <span
                      style={{ cursor: "help" }}
                      onClick={() => openModal("calculationMethodInfo")}
                    >
                      (Help)
                    </span>
                  </Text>
                </p>
                <Select
                  // Default -> advancedDiscountAdding: true, Advanced (Beta) -> advancedDiscountAdding: false
                  // Confusing, I know...
                  options={[
                    { label: "Default", value: true },
                    { label: "Advanced (Beta)", value: false },
                  ]}
                  onChange={() => handleChange("advancedDiscountAdding")}
                  value={!!advancedDiscountAdding}
                />
              </Card>
            )}
          </FormLayout>
        </Form>
      </Layout.AnnotatedSection>
    );
  }
}

export default GeneralSettings;
