import React, { Component } from "react";
import {
  Layout,
  LegacyCard as Card,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  VerticalStack,
} from "@shopify/polaris";

class Skeleton extends Component {
  render() {
    return (
      <SkeletonPage primaryAction secondaryActions={2}>
        <Layout>
          <Layout.Section>
            <VerticalStack gap={5}>
              <Card sectioned>
                <SkeletonBodyText />
              </Card>
              <Card sectioned>
                <VerticalStack gap={4}>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText />
                </VerticalStack>
              </Card>
              <Card sectioned>
                <VerticalStack gap={4}>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText />
                </VerticalStack>
              </Card>
            </VerticalStack>
          </Layout.Section>
          <Layout.Section secondary>
            <VerticalStack gap={5}>
              <Card sectioned>
                <VerticalStack gap={4}>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={2} />
                </VerticalStack>
              </Card>
              <Card subdued sectioned>
                <VerticalStack gap={4}>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={2} />
                </VerticalStack>
              </Card>
            </VerticalStack>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );
  }
}

export default Skeleton;
