import React, { Component } from "react";
import { DesignCard } from "./";
import { Layout, Button, ButtonGroup } from "@shopify/polaris";
import "./DesignSelection.css";

class DesignSelection extends Component {
  render() {
    const {
      styles,
      handleActivation,
      openModal,
      openWizard,
      shop,
      handleDesignDelete,
      isAdmin,
    } = this.props;
    return (
      <Layout.AnnotatedSection
        title="Design"
        description={`Customize the location of the discount field,
				adjust the style using CSS or add custom classes to match your theme.`}
      >
        <ButtonGroup fullWidth className="cdButtons">
          {isAdmin && (
            <Button url={openWizard()} external>
              Start setup wizard
            </Button>
          )}
          {/*<Button disabled={!featuresUnlocked}>Search existing designs</Button>*/}
          <Button onClick={() => openModal("configrequest")}>
            Request handmade design
          </Button>
        </ButtonGroup>
        <div className="cardGrid">
          {styles
            ? styles.map((element) => {
                const {
                  id,
                  title,
                  theme,
                  description,
                  active,
                  shop: owner,
                } = element;
                console.log(shop, owner);
                return (
                  <DesignCard
                    key={id}
                    id={id}
                    title={title}
                    description={description}
                    active={active}
                    theme={theme}
                    handleActivation={handleActivation}
                    openModal={openModal}
                    openWizard={openWizard}
                    deletable={shop === owner}
                    handleDelete={handleDesignDelete}
                    isAdmin={isAdmin}
                  />
                );
              })
            : ""}
        </div>
      </Layout.AnnotatedSection>
    );
  }
}

export default DesignSelection;
