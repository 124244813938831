import React, { Component } from "react";
import { Modal, FormLayout, TextField, Select, Text } from "@shopify/polaris";
import "./DesignEditModal.css";

class DesignEditModal extends Component {
  render() {
    const { active, handleClose, handleSave, handleChange, config } =
      this.props;
    const {
      title,
      theme,
      description,
      anchor,
      location,
      infoboxAnchor,
      infoboxLocation,
      classes,
      style,
      copy,
      additionalStyle,
      discountActiveStyle,
    } = config;
    const anchorOptions = [
      { label: "before (outside) of", value: "beforebegin" },
      { label: "after (outside) of", value: "afterend" },
      { label: "at the beginning (inside) of", value: "afterbegin" },
      { label: "at the end (inside) of", value: "beforeend" },
    ];
    return (
      <Modal
        large
        open={active}
        title={"Edit '" + title + "'"}
        onClose={handleClose}
        primaryAction={{
          content: "Save",
          onAction: handleSave,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleClose,
          },
        ]}
        footer={
          copy
            ? "You're editing a premade design. Changes are saved in a new design."
            : ""
        }
      >
        <Modal.Section>
          <FormLayout>
            <div className="modalHalfLeft">
              <div className="formSection">
                <Text variant="headingXs" as="h3">
                  General
                </Text>
                <div className="formGrid">
                  <TextField
                    label="Title"
                    onChange={handleChange("title")}
                    value={title}
                  />
                  <TextField
                    label="Theme"
                    onChange={handleChange("theme")}
                    value={theme}
                  />
                </div>
                <TextField
                  multiline
                  label="Description"
                  onChange={handleChange("description")}
                  value={description}
                />
              </div>
              <div className="formSection">
                <Text variant="headingXs" as="h3">
                  Location
                </Text>
                <Text variant="bodySm" as="p">
                  Target a location in your theme using a CSS selector (more
                  information).
                </Text>
                <p>Target location of discount input field</p>
                <div className="formPair">
                  <Select
                    label="Insert"
                    labelInline
                    options={anchorOptions}
                    onChange={handleChange("location")}
                    value={location}
                  />
                  <TextField onChange={handleChange("anchor")} value={anchor} />
                </div>
                <p className="dcPadding12">Target location of info box</p>
                <div className="formPair">
                  <Select
                    label="Insert"
                    labelInline
                    options={anchorOptions}
                    onChange={handleChange("infoboxLocation")}
                    value={infoboxLocation}
                  />
                  <TextField
                    onChange={handleChange("infoboxAnchor")}
                    value={infoboxAnchor}
                  />
                </div>
              </div>
            </div>
            <div className="modalHalfRight">
              <div className="formSection">
                <Text variant="headingXs" as="h3">
                  Additional CSS classes
                </Text>
                <Text variant="bodySm" as="p">
                  Separate classes with a space
                </Text>
                <div className="formGrid">
                  <TextField
                    label="Outer wrapper"
                    onChange={handleChange("classes", "outerWrapper")}
                    value={classes.outerWrapper}
                  />
                  <TextField
                    label="Inner wrapper"
                    onChange={handleChange("classes", "innerWrapper")}
                    value={classes.innerWrapper}
                  />
                  <TextField
                    label="Discount inputfield"
                    onChange={handleChange("classes", "input")}
                    value={classes.input}
                  />
                  <TextField
                    label="Button"
                    onChange={handleChange("classes", "button")}
                    value={classes.button}
                  />
                </div>
              </div>
              <div className="formSection">
                <Text variant="headingXs" as="h3">
                  Additional styling
                </Text>
                <Text variant="bodySm" as="p">
                  Using CSS rules
                </Text>
                <div className="formGrid">
                  <TextField
                    multiline="3"
                    label="Outer wrapper"
                    onChange={handleChange("style", "outerWrapper")}
                    value={style.outerWrapper}
                  />
                  <TextField
                    multiline="3"
                    label="Inner wrapper"
                    onChange={handleChange("style", "innerWrapper")}
                    value={style.innerWrapper}
                  />
                  <TextField
                    multiline="3"
                    label="Discount inputfield"
                    onChange={handleChange("style", "input")}
                    value={style.input}
                  />
                  <TextField
                    multiline="3"
                    label="Button"
                    onChange={handleChange("style", "button")}
                    value={style.button}
                  />
                </div>
              </div>
              <div className="formSection">
                <Text variant="headingXs" as="h3">
                  Advanced CSS
                </Text>
                <Text variant="bodySm" as="p">
                  Using CSS selectors and rules
                </Text>
                <div className="formGrid">
                  <TextField
                    multiline="3"
                    label="CSS added on pageload"
                    onChange={handleChange("additionalStyle")}
                    value={additionalStyle}
                  />
                  <TextField
                    multiline="3"
                    label="CSS added when a discount is active"
                    onChange={handleChange("discountActiveStyle")}
                    value={discountActiveStyle}
                  />
                </div>
              </div>
            </div>
          </FormLayout>
        </Modal.Section>
      </Modal>
    );
  }
}

export default DesignEditModal;
