import React, { Component } from "react";
import { Page, Layout, Button, ButtonGroup, Text } from "@shopify/polaris";
import {
  DesignSelection,
  LabelSettings,
  SellCard,
  GeneralSettings,
} from "../newcomponents";

class Settings extends Component {
  render() {
    const {
      labels,
      handleLabelSubmit,
      styles,
      plan,
      handleDesignActivation,
      openModal,
      handleGeneralSettingsChange,
      appActive,
      calcActive,
      advancedDiscountAdding,
      apiAddress,
      shop,
      openWizard,
      handleDesignDelete,
      isAdmin,
    } = this.props;

    return (
      <Page>
        <Layout>
          <Layout.Section>
            <Text variant="heading4xl" as="p">
              Settings
            </Text>
            <ButtonGroup>
              {/*<Button plain>Manage plan</Button>
							<Button plain>FAQ</Button>*/}
              <Button plain onClick={() => openModal("support")}>
                Support
              </Button>
            </ButtonGroup>
          </Layout.Section>
          <SellCard
            plan={plan}
            apiAddress={apiAddress}
            shop={shop}
            openModal={openModal}
          />
          <GeneralSettings
            handleChange={handleGeneralSettingsChange}
            appActive={appActive}
            calcActive={calcActive}
            advancedDiscountAdding={advancedDiscountAdding}
            openModal={openModal}
            plan={plan}
            apiAddress={apiAddress}
            shop={shop}
          />
          <DesignSelection
            styles={styles}
            plan={plan}
            handleActivation={handleDesignActivation}
            openModal={openModal}
            openWizard={openWizard}
            shop={shop}
            handleDesignDelete={handleDesignDelete}
            isAdmin={isAdmin}
          />
          <LabelSettings labels={labels} handleSubmit={handleLabelSubmit} />
        </Layout>
      </Page>
    );
  }
}

export default Settings;
